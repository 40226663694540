.container {
  position: relative;
  pointer-events: auto;
}

.container :global(.DayPicker) {
  width: 20rem; /* w-80 */
}

.container :global(.DayPicker-Weekdays) {
  display: block;
  margin: 0;
}

.container :global(.DayPicker-Month) {
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container :global(.DayPicker-Week),
.container :global(.DayPicker-WeekdaysRow) {
  display: grid;
  gap: 0.5rem;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  grid-template-columns: repeat(7, 2rem);
}

.container :global(.DayPicker-Week) {
  background-color: rgba(33, 28, 92, 0.05);
}

.container :global(.DayPicker-Day.DayPicker-Day--outside),
.container :global(.DayPicker-Day:not(.DayPicker-Day--selected)) {
  background-color: white;
  border-radius: 0;
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}

.container :global(.DayPicker-wrapper) {
  padding: 0;
}

.container :global(.DayPicker-Weekday) {
  font-size: 0.875rem;
  font-weight: 600;
}

.container :global(.DayPicker-Day) {
  font-size: 0.875rem;
  border-radius: 0.25rem;
  color: #122c34;
}

.container
  :global(.DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside)) {
  color: #211c5c;
  border-radius: 0;
  background-color: transparent;
}

.container :global(.DayPicker-Day--selected.DayPicker-Day--start)::before {
  content: '';
  position: absolute;
  background-color: white;
  top: 0;
  width: 0.25rem;
  height: 100%;
  left: -0.25rem;
}

.container :global(.DayPicker-Day--selected.DayPicker-Day--end)::after {
  content: '';
  position: absolute;
  background-color: white;
  top: 0;
  width: 0.25rem;
  height: 100%;
  left: 100%;
}

.container :global(.DayPicker-Day--selected.DayPicker-Day--start:not(.DayPicker-Day--outside)),
.container :global(.DayPicker-Day--selected.DayPicker-Day--end:not(.DayPicker-Day--outside)) {
  background-color: #211c5c;
  color: #ffffff;
  font-weight: 600;
}

.container
  :global(
    .DayPicker-Day--selected.DayPicker-Day--start:not(.DayPicker-Day--outside)
      + .DayPicker-Day--selected.DayPicker-Day--end:not(.DayPicker-Day--outside)
  )::before {
  content: '';
  width: 0.75rem;
  height: 100%;
  position: absolute;
  left: -0.5rem;
  top: 0;
  background-color: #211c5c;
}

.container
  :global(.DayPicker-Day--selected.DayPicker-Day--start:not(.DayPicker-Day--end):not(.DayPicker-Day--outside)) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.container
  :global(.DayPicker-Day--selected.DayPicker-Day--end:not(.DayPicker-Day--start):not(.DayPicker-Day--outside)) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.container :global(.DayPicker-Day.DayPicker-Day--selected:not(.DayPicker-Day--outside):hover) {
  background-color: #211c5c;
  color: #ffffff;
}

.container :global(.DayPicker-Day--disabled) {
  color: var(--color-grayscale-16);
  cursor: not-allowed;
}
